import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const NoMatch = () => {
    return (
        <section>
            <div className="px-5 lg:px-12">
                <div className='text-center my-16'>
                    <h2 className='text-[100px] lg:text-[200px] font-extrabold text-rose-500'>4<span>0</span>4</h2>
                    <h2 className='text-[30px] font-semibold'>Page Not Found</h2>
                    <h4 className='md:w-[40%] md:mx-auto m-0 text-gray-500 my-4 text-[22px]'>We’re could't find this page? please to go</h4>
                    <div className="text-center">
                        <Link to='/' className='my-4  mx-auto flex items-center justify-center '>
                            <button className='flex items-center justify-center bg-blue-500 px-5 rounded-lg hover:bg-pink-500 hover:translate-x-5 duration-[0.5s] ease-in-out transition-all py-2 text-white gap-1 font-semibold text-[18px]'>Go to homepage<FaArrowRight /></button>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NoMatch;
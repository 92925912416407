import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi'
import { toast } from 'react-toastify';
import { backendAPI } from '../../backendapi/BackendAPI';
import ContactModal from './ContactModal'


const DashContact = () => {
    const [user, setUser] = useState([]);
    const [loadding, setLoadding] = useState(false);

    useEffect(() => {
        setLoadding(true);
        fetch(`${backendAPI}/contact/getAllContact`)
            .then(res => res.json())
            .then(data => {
                setUser(data);
                setLoadding(false)
            })
    }, [user])


    //Remove Single Users
    const handleDeleteUser = (id) => {
        const user = window.confirm('Do you want delete this Message?');

        if (user) {
            fetch(`${backendAPI}/contact/removeContactItem/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(result => {
                    if (result?.success) {
                        toast.success(result?.message)
                    } else {
                        if (result?.error) {
                            toast.error(result?.message)
                        }
                    }
                })
        }
    }


    return (
        <section>
            <div className="show-contact">

                {
                    user?.length === 0 ?
                        <h2 className='text-slate-800 font-medium my-12 text-2xl'>Loadding Please Wait.......</h2>
                        :
                        <>
                            <div className="user-list">
                                <div className='my-8'>
                                    <h1 className=' text-[25px] font-semibold text-slate-800 capitalize my-5'> users contact List</h1>

                                    <div className="shadow-md  w-1/3 md:w-full overflow-x-hidden">
                                        <table className="w-full">
                                            <thead className="bg-slate-300 ">
                                                <tr className='text-center rounded-xl'>
                                                    <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Serial</th>
                                                    {/* <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Photo</th> */}
                                                    <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Name</th>
                                                    <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Email</th>
                                                    <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Subject</th>
                                                    <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Time</th>
                                                    <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Messsage</th>
                                                    <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    user?.map((user, index) => {
                                                        const { name, subject, email, _id, time, message } = user;
                                                        return (
                                                            <tr className="border border-transparent hover:border hover:border-blue-400 cursor-pointer transition my-5 ease-in-out duration-75 shadow-xl" key={index}>
                                                                <td className="px-6 py-4 text-center font-medium text-slate-500 text-[20px]">{index + 1}</td>
                                                                <td className="px-6 py-4 text-center text-[18] capitalize font-medium text-slate-500">{name}</td>
                                                                <td className="px-6 py-4 text-center text-[18] font-medium text-slate-500">{email}</td>
                                                                <td className="px-6 py-4 text-center text-[18] font-medium text-slate-500">{subject}</td>
                                                                <td className="px-6 py-4 text-center text-[18] font-medium text-slate-500">{time}</td>
                                                                <td className="px-6 py-4 text-center text-[18] font-medium text-slate-500"><ContactModal message={message} /></td>

                                                                <td className="px-6 py-4 text-center">
                                                                    <FiTrash2 className='text-red-400 cursor-pointer text-center text-[20px] mx-auto' onClick={() => handleDeleteUser(_id)} />
                                                                </td>

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </section>
    );
};

export default DashContact;
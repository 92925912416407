import React from 'react';
import useActiveUser from '../../../hooks/useActiveUser';

const DashWelcome = () => {
    const [user] = useActiveUser();
    return (
        <div className='my-12'>
            <h2 className='text-2xl font-semibold text-slate-800'>Hello, <span className='text-blue-400 '> {user?.userName} </span>Welcome to DashBoard</h2>
        </div>
    );
};

export default DashWelcome;
import React from 'react';
import logo from '../../images/extra/dellr.jpg'
import { Bounce, Slide } from 'react-reveal';
import { FaArrowRight, FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const Service = () => {
    return (
        <section>
            <div className='allblog_item'>
                <Bounce left>
                    <div className="px-5 lg:px-12 my-24">
                        <h2 className='text-white font-bold mb-2 text-center lg:text-left text-6xl uppercase'>our services</h2>
                        <div className="flex items-center justify-center lg:justify-start gap-3">
                            <Link to='/' className='text-white font-medium text-2xl'>
                                Home
                            </Link>
                            <FaArrowRight className='text-white mt-1' />
                            <h2 className='text-white font-medium text-2xl'>Choose Your services</h2>
                        </div>
                    </div>
                </Bounce>
            </div>
            <div className="px-5 lg:px-12 my-24">
                <div className="grid items-center xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-10">
                    <div className="title">
                        <Slide left>
                            <h2 className='text-slate-500 font-semibold uppercase text-2xl'>Our Services</h2>
                            <h2 className='text-slate-800 font-bold text-4xl'>What We do Provide Services</h2>
                            <h2 className='text-slate-800 font-bold text-4xl mb-5'>Car Selling Services</h2>
                            <p className='text-gray-600 text-[20px] font-[400]'>N.Z Corporations株式会社 is a Japanese company specializing in the sale of high-quality second-hand cars. Established in July 2023, the company has quickly gained a reputation for its commitment to customer satisfaction and the sale of reliable vehicles. Here are some additional details</p>

                            <div className="service-sub my-5">
                                <div className="flex items-center flex-wrap gap-x-5 gap-y-2">
                                    <p className='flex items-center gap-2 font-semibold text-gray-600 text-[18px]'>
                                        <FaCheck className='text-orange-400' />
                                        Quality Assurance
                                    </p>
                                    <p className='flex items-center gap-2 font-semibold text-gray-600 text-[18px]'>
                                        <FaCheck className='text-orange-400' />
                                        Marketing and Sustainability
                                    </p>
                                </div>
                                <div className="flex items-center flex-wrap gap-x-5 gap-y-2 lg:my-3">
                                    <p className='flex items-center gap-2 font-semibold text-gray-600 text-[18px]'>
                                        <FaCheck className='text-orange-400' />
                                        Legal Compliance
                                    </p>
                                    <p className='flex items-center gap-2 font-semibold text-gray-600 text-[18px]'>
                                        <FaCheck className='text-orange-400' />
                                        Customer-Centric Approach
                                    </p>

                                </div>
                            </div>
                            <Link to='/' className='my-4 block'>
                                <button className='flex items-center bg-blue-500 px-5 rounded-lg hover:bg-pink-500 hover:translate-x-5 duration-[0.5s] ease-in-out transition-all py-2 text-white gap-1 font-semibold text-[18px]'>Expole Now <FaArrowRight /></button>
                            </Link>
                        </Slide>
                    </div>
                    <div className="service-thub">
                        <Slide right>
                            <img src={logo} alt="car service" className='object-cover rounded-md' />
                        </Slide>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Service;
import React, { useState } from 'react';
import Zoom from 'react-reveal/Zoom';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BsArrowRightCircle } from 'react-icons/bs';
import { backendAPI } from '../../backendapi/BackendAPI';


const Login = () => {
    const [loadding, setLoadding] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [account, setAccount] = useState('');
    const navigate = useNavigate();

    const onSubmit = data => {
        setLoadding(true)
        fetch(`${backendAPI}/auth/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(result => {
                console.log("result...", result);
                if (result?.message2) {
                    setAccount(result?.message2)
                }
                if (result?.success) {
                    toast.success(result?.message);
                    localStorage.setItem('token', result?.token)
                    setAccount('');
                    reset();
                    navigate('/')
                    setLoadding(false)
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                        setLoadding(false)
                    }
                }
            })
    }


    return (
        <section className='login-section overflow-hidden'>
            <div className="container mx-auto px-5 my-32">
                <div className="login-right">
                    <Zoom>
                        <div className="login-form shadow-2xl bg-slate-800 rounded-xl p-4 mx-auto md:w-[35%] w-full">
                            <h2 className='text-white text-[25px] font-medium text-center'>Login</h2>
                            <h2 className='text-orange-500 text-[18px] font-semibold text-center'>{account}</h2>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="login-details">
                                    <div className="login-form1 my-4">
                                        <h3 className='text-white font-medium pb-[2px] text-[18px]'>Email</h3>
                                        <input {...register("email", { required: true })} type='email' placeholder="Email address" className="mt-2 flex text-white w-full items-center  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-[17px] outline-none border-gray-500" />
                                        {errors.email && <span className='text-yellow-500 text-[18px]'>Email fill required</span>}
                                    </div>
                                    <div className="login-form1 my-2">
                                        <h3 className='text-white font-medium pb-[2px] text-[18px]'>Password</h3>
                                        <input {...register("password", { required: true })} type='password' placeholder="Your Password" className="mt-2 flex text-white w-full items-center  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-[17px] outline-none border-gray-500" />
                                        {errors.password && <span className='text-yellow-500 text-[18px]'>Password fill is required</span>}
                                    </div>
                                    <div className="forgetPassword">
                                        <Link to='/find-account' className='text-end block text-gray-400 font-medium text-[16px]'>
                                            Forget Password ?
                                        </Link>
                                    </div>
                                    <div className="login-form1 my-4">
                                        {
                                            loadding ? <button className='flex items-center justify-center text-center gap-2  bg-gradient-to-r text-[18px] my-2 font-medium w-full text-white from-rose-500 to-rose-600 hover:from-orange-500 hover:to-red-500 rounded-xl p-2' disabled>Logging please wait.. <span className='loader2'></span></button>
                                                :

                                                <button className='flex items-center gap-2  bg-gradient-to-r text-[18px] my-2 font-medium text-white w-full justify-center  from-[#0B5ED7] to-blue-500 hover:from-orange-500 hover:to-red-500 rounded-xl p-2'>Login <BsArrowRightCircle /></button>
                                        }

                                    </div>
                                </div>
                            </form>
                            <h3 className='text-gray-400 font-medium pb-1 text-[18px] text-center'>You have no an account ?  <Link to='/register' className='text-orange-400 font-medium'>
                                Register
                            </Link>  </h3>
                        </div>
                    </Zoom>
                </div>
            </div>
        </section>
    );
};

export default Login;
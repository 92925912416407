import React from 'react';
import Zoom from 'react-reveal/Zoom';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { BsArrowRightCircle } from 'react-icons/bs';
import { backendAPI } from '../../backendapi/BackendAPI';



const Register = () => {
    const [loadding, setLoadding] = useState(false);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const onSubmit = data => {
        setLoadding(true)
        fetch(`${backendAPI}/auth/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(result => {
                if (result?.error?.includes('E11000 duplicate key error collection')) {
                    setEmail('You already use this email');
                }
                if (result?.success) {
                    toast.success(result?.message);
                    reset();
                    setEmail('');
                    navigate('/login')
                    setLoadding(false)
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                    }
                }
            })
    }


    return (
        <section className='login-section overflow-hidden'>
            <div className="container mx-auto px-5 my-32">
                <div className="login-right">
                    <Zoom>
                        <div className="login-form shadow-2xl bg-slate-800 rounded-xl p-4 mx-auto md:w-[35%] w-full">
                            <h2 className='text-white text-[20px] font-medium text-center'>Register</h2>
                            <h2 className='text-yellow-500 text-[18px] font-semibold text-center'>{email}</h2>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="login-details">
                                    <div className="login-form1 my-4">
                                        <h3 className='text-white font-medium pb-[2px] text-[18px]'>Full Name</h3>
                                        <input {...register("userName", { required: true })} type='text' placeholder="Name" className="mt-2 flex text-white w-full items-center  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-[18px] outline-none border-gray-500" />
                                        {errors.userName && <span className='text-yellow-500'>FullName fill is required</span>}
                                    </div>
                                    <div className="login-form1 my-4">
                                        <h3 className='text-white font-medium pb-[2px] text-[18px]'>Email</h3>
                                        <input {...register("email", { required: true })} type='email' placeholder="Email address" className="mt-2 flex text-white w-full items-center  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-[18px] outline-none border-gray-500" />
                                        {errors.email && <span className='text-yellow-500'>Email fill required</span>}
                                    </div>
                                    <div className="login-form1 my-2">
                                        <h3 className='text-white font-medium pb-[2px] text-[18px]'>Password</h3>
                                        <input {...register("password", { required: true })} type='password' placeholder="Create new Password" className="mt-2 flex text-white w-full items-center  focus:ring-2 focus:ring-blue-400 justify-center rounded-xl border bg-white/0 p-3 text-[18px] outline-none border-gray-500" />
                                        {errors.password && <span className='text-yellow-500'>Password fill is required</span>}
                                    </div>
                                    <div className="register-form1 my-4">
                                        {
                                            loadding ? <button className='flex items-center justify-center text-center gap-2  bg-gradient-to-r text-[18px] my-2 font-medium w-full text-white from-rose-500 to-rose-600 hover:from-orange-500 hover:to-red-500 rounded-xl p-2' disabled>Register please wait.. <span className='loader2'></span></button>
                                                :

                                                <button className='flex items-center gap-2  bg-gradient-to-r text-[18px] my-2 font-medium text-white w-full justify-center  from-[#0B5ED7] to-blue-500 hover:from-orange-500 hover:to-red-500 rounded-xl p-2'>Register <BsArrowRightCircle /></button>
                                        }
                                    </div>
                                </div>
                            </form>
                            <h3 className='text-gray-400 font-medium pb-1 text-[18px] text-center'>Have an account ?  <Link to='/login' className='text-orange-400 font-medium'>
                                Login
                            </Link>  </h3>
                        </div>
                    </Zoom>
                </div>
            </div>
        </section>
    );
};

export default Register;
import React from 'react';
import DashSlide from './dashslidebar/DashSlide';
import { Outlet } from 'react-router-dom';

const DashBoard = () => {
    return (
        <div className='flex gap-x-8 w-full'>
            <div className="slidebar bg-slate-800 overflow-y-scroll h-screen">
                <DashSlide />
            </div>
            <div className="slidebar w-full overflow-hidden px-5">
                <Outlet />
            </div>
        </div>
    );
};

export default DashBoard;
import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BsArrowRightCircle } from 'react-icons/bs';
import { MdCloudUpload } from 'react-icons/md';
import { backendAPI } from '../../backendapi/BackendAPI';







const AddCar = () => {
    const [loadding, setLoadding] = useState(false);
    const [image, setImage] = useState('');
    const [preview, setPreview] = useState()
    const [disc, setDisc] = useState('');
    const [title, setTitle] = useState('');
    const imageBBAPi = 'edc14897441fe74ab954ba60e0f4bf2e'
    const navigate = useNavigate();




    const handleUpload = (e) => {
        setImage(e.target.files[0])
        setPreview(URL.createObjectURL(e.target.files[0]))

    }



    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("image", image)


        // get all data form api 
        setLoadding(true)
        await fetch(`https://api.imgbb.com/1/upload?key=${imageBBAPi}`, {
            method: 'POST',
            body: formData
        })
            .then(res => res.json())
            .then(result => {
                if (result?.success) {
                    const imageUrl = result?.data?.url;
                    const sendData = {
                        thubnail: imageUrl,
                        title: title,
                        disc: disc,
                    }

                    //send post in mongodb
                    setLoadding(true)
                    fetch(`${backendAPI}/car/postCar`, {
                        method: 'POST',
                        headers: {
                            'content-type': 'application/json'
                        },
                        body: JSON.stringify(sendData)
                    })
                        .then(res => res.json())
                        .then(result => {
                            if (result.success) {
                                toast.success(result?.message)
                                navigate('/')
                                setLoadding(false);

                                setImage('')
                            } else {
                                if (result.error) {
                                    toast.error(result?.message)

                                }
                            }

                        })
                }
            })
    }



    return (
        <section className='notice-section overflow-hidden'>
            <div className="container mx-auto p-5 shadow-xl rounded-xl shadow-gray-200">
                <h2 className='capitalize mb-3 font-bold text-slate-800 center text-2xl'>Add Car Hero Section</h2>
                <div className="notice-extra">
                    <form onSubmit={handleSubmit}>
                        <div className="form2">
                            <label htmlFor="title" className='font-semibold text-[18px] text-slate-800 mb-2'>Title </label>
                            <input type="text" placeholder='Slide Title ' id="title" onChange={(e) => setTitle(e.target.value)} className='p-4 text-[18px] focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' required />
                        </div>
                        <div className="form2 my-5">
                            <label htmlFor="disc" className='font-semibold text-[18px] text-slate-800 mb-2'>Discription </label>
                            <input type="text" placeholder='Short Discription' id="disc" onChange={(e) => setDisc(e.target.value)} className='p-4 text-[18px] focus:ring-2 w-full focus:ring-blue-400 bg-[#EEF9FF] placeholder:text-gray-500 outline-none' required />
                        </div>
                        <div className="form2 my-5">
                            <h2 className='font-semibold text-[18px] text-slate-800 mb-2'>Car Slider Photo</h2>
                            {
                                image ? <img src={preview} alt="review " className='w-auto rounded-xl h-[200px] object-cover' /> : ''
                            }
                            {
                                image ? '' : <label htmlFor="fff" className='cursor-pointer'><MdCloudUpload className='text-[50px] text-slate-800 flex items-center justify-center' /> </label>
                            }

                            <input type="file" onChange={handleUpload} className='hidden' id='fff' />
                            <br />
                        </div>

                        <div className="notice-form1 my-4">
                            {
                                loadding ?
                                    <button className='flex items-center gap-2 rounded-lg bg-gradient-to-r text-[18px] w-full text-center my-2 font-medium justify-center text-white bg-pink-500 p-4' disabled>Car Adding Please wait...<span class="loader2"></span> </button>
                                    :
                                    <button className='flex items-center gap-2 rounded-lg bg-gradient-to-r text-[18px] w-full text-center my-2 font-medium justify-center text-white bg-blue-500 p-4'>Publish Car Slider<BsArrowRightCircle /></button>
                            }
                        </div>

                    </form>
                </div>
            </div>
        </section >
    );
};


export default AddCar;
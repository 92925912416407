import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/shared/navbar/Navbar';
import Home from './pages/Home/Home';
import TopBar from './components/shared/topbar/TopBar';
import Footer from './components/shared/footer/Footer';
import HotToast from './components/shared/maintoast/HotToast';
import DashBoard from './components/dashboard/DashBoard';
import AllUser from './components/dashboard/allusers/AllUser';
import DashContact from './components/dashboard/contact/DashContact';
import Login from './components/authentication/login/Login';
import Register from './components/authentication/register/Register';
import FindAccount from './components/authentication/findaccount/FindAccount';
import ForgetPassword from './components/authentication/forgetPassword/ForgetPassword';
import Contact from './pages/contact/Contact';
import About from './pages/about/About';
import NoMatch from './components/shared/NoMatch/NoMatch';
import Profile from './components/authentication/profile/Profile';
import ScrollTop from './components/shared/scrolltop/ScrollTop';
import DownToTop from './components/shared/downtotop/DownToTop';
import NewsFeed from './components/dashboard/newsFeed/NewsFeed';
import Admin from './components/dashboard/admin/Admin';
import DashWelcome from './components/dashboard/dashwelcome/DashWelcome';
import ProtectedRoute from './components/authentication/protectedRoute/ProtectedRoute';
import { useEffect } from 'react';
import HomeAnimation from './components/animation/HomeAnimation';
import { useState } from 'react';
import Service from './pages/services/Service';
import Car from './components/dashboard/addcar/Car';
import useActiveUser from './hooks/useActiveUser';
import SlideMarquee from './components/shared/marquee/SlideMarquee';
import TextTitle from './components/dashboard/titleslide/TextTitle';

const App = () => {
  const [loadding, setLoadding] = useState(true);

  const [user] = useActiveUser();

  useEffect(() => {
    setTimeout(() => {
      setLoadding(false);
    }, 1500);
  }, [])


  return (
    <>
      {
        loadding ? <HomeAnimation /> :

          <BrowserRouter>
            <TopBar />
            <SlideMarquee />
            <Navbar />
            <ScrollTop />
            <DownToTop />
            <Routes>

              {/* Page start  */}
              <Route path='/' element={<Home />}></Route>
              <Route path='/contact' element={<Contact />}></Route>
              <Route path='/about' element={<About />}></Route>
              <Route path='/services' element={<Service />}></Route>
              {/* Page End  */}

              {/* Authentication start  */}
              <Route path='/login' element={<Login />}></Route>
              <Route path='/register' element={<Register />}></Route>
              <Route path='/find-account' element={<FindAccount />}></Route>
              <Route path='/forget-password' element={<ForgetPassword />}></Route>
              <Route path='/profile' element={<Profile />}></Route>
              {/* Authentication end  */}

              {/* Dashboard Start   */}

              {
                user?.role === 'admin' ?
                  <>
                    <Route path='/dashboard' element={<ProtectedRoute />}>
                      <Route path='/dashboard' element={<DashBoard />}>
                        <Route index element={<DashWelcome />}></Route>
                        <Route path='/dashboard/all-users' element={<AllUser />}></Route>
                        <Route path='/dashboard/contact' element={<DashContact />}></Route>
                        <Route path='/dashboard/add-car' element={<Car />}></Route>
                        <Route path='/dashboard/subscribe' element={<NewsFeed />}></Route>
                        <Route path='/dashboard/admin' element={<Admin />}></Route>
                        <Route path='/dashboard/title' element={<TextTitle />}></Route>
                      </Route>
                    </Route>
                  </> :
                  <Route path='/login' element={<Login />}></Route>
              }
              {/* Dashboard End   */}

              {/* No Match Route  */}
              <Route path='*' element={<NoMatch />}></Route>

            </Routes>
            <Footer />
            <HotToast />
          </BrowserRouter>
      }
    </>
  );
};

export default App;
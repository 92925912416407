import { useEffect } from "react";
import { useState } from "react"
import { backendAPI } from "../components/backendapi/BackendAPI";

const useActiveUser = () => {
    const [user, setUser] = useState({});

    //load user 
    useEffect(() => {
        fetch(`${backendAPI}/auth/loginUser`, {
            method: 'GET',
            headers: {
                "Content-Type": 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => res.json())
            .then(data => setUser(data))
    }, [user])

    return [user];
}

export default useActiveUser;
import React, { useState } from 'react';
import RubberBand from 'react-reveal/RubberBand'
import { FaArrowRight, FaEnvelopeOpen, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';
import Zoom from 'react-reveal/Zoom'
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Bounce, Slide } from 'react-reveal'
import { backendAPI } from '../../components/backendapi/BackendAPI';
import { Link } from 'react-router-dom';



// Fake Data

const contactData = [
    {
        title: 'Call to ask any question',
        disc: '070-4343-3767',
        logo: <FaPhoneAlt />,
        contact: '047-199-2072'
    },
    {
        title: 'Email to get free quote',
        disc: 'n.zcorporationltd@gmail.com',
        logo: <FaEnvelopeOpen />
    },
    {
        title: 'Visit our Japan office',
        disc: '千葉県野田市木間ケ瀬 4483-2',
        logo: <FaMapMarkerAlt />
    },
    {
        title: 'Visit our Branch office Bangladesh',
        disc: '86 Inner circular VIP road,  kazi tower (9th floor)Nayapaltan, Dhaka-1000',
        logo: <FaMapMarkerAlt />,
        contact: 'Contact number : +8801409961466'
    }


]

const Contact = () => {
    const { register, handleSubmit, reset, formState: { errors }, } = useForm()
    const [loadding, setLoadding] = useState(false);

    const onSubmit = (data) => {
        const storeData = {
            name: data?.name,
            email: data?.email,
            subject: data?.subject,
            message: data?.message,
            time: new Date().toDateString()
        }

        setLoadding(true);
        fetch(`${backendAPI}/contact/contactPost`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(storeData)
        })
            .then(res => res.json())
            .then(result => {
                if (result?.success) {
                    toast.success(result?.message)
                    reset();
                    setLoadding(false);
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                        setLoadding(false);
                    }
                }
            })
    }






    return (
        <section>
            <div className='allblog_item'>
                <Bounce left>
                    <div className="px-5 lg:px-12 my-24">
                        <h2 className='text-white font-bold mb-2 text-center lg:text-left text-6xl uppercase'>Contact US</h2>
                        <div className="flex items-center justify-center lg:justify-start gap-3">
                            <Link to='/' className='text-white font-medium text-2xl'>
                                Home
                            </Link>
                            <FaArrowRight className='text-white mt-1' />
                            <h2 className='text-white font-medium text-2xl'>Contact with us</h2>
                        </div>
                    </div>
                </Bounce>
            </div>
            <div className="px-5 lg:px-12">
                <div className="text-center my-24">
                    <RubberBand>
                        <h2 className='main-title'>Contact With US</h2>
                        <p className='text-slate-700 text-[19px] font-[500]'>24/7 Customer Support Services and Call Center</p>
                    </RubberBand>
                </div>
                <div className="main-contact">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-5">
                        <Zoom>
                            {
                                contactData?.map((item, index) => {
                                    const { title, logo, disc, contact } = item
                                    return (
                                        <div className="counter-item shadow-lg hover:border border-none border-transparent bg-white rounded-lg p-5" key={index}>
                                            <div className="flex gap-5">
                                                <div className="logo">
                                                    <div className="bg-[#06A3DA] p-3 rounded-md">
                                                        <h2 className='text-white text-2xl font-semibold'>{logo}</h2>
                                                    </div>
                                                </div>
                                                <div className="logo">
                                                    <h2 className='text-[19px] text-slate-700 capitalize  mb-1 font-semibold'>{title}</h2>
                                                    <h2 className='text-[22px]  font-bold text-slate-800'>{disc}</h2>
                                                    <h2 className='text-[22px]  font-bold text-slate-800'>{contact}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Zoom>
                    </div>
                </div>
                <div className="contact-google my-24">
                    <h2 className='text-[20px] text-slate-800 capitalize  mb-2 font-semibold'>Send us a Message</h2>
                    <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-7">
                        <div className="contact-form">
                            <Slide left>
                                <div className="lg:w-[95%] w-full">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-2 gap-2">
                                            <div className="form1">
                                                <input {...register("name", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 text-[18px] bg-[#7bd9f842] placeholder:text-gray-500 outline-none' placeholder='Your Name' />
                                                {errors.name && <span className='text-red-500'>This field is required</span>}
                                            </div>
                                            <div className="form1">
                                                <input {...register("email", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400 text-[18px] bg-[#7bd9f842] placeholder:text-gray-500 outline-none' placeholder='Your Email' />
                                                {errors.email && <span className='text-red-500'>This field is required</span>}
                                            </div>
                                        </div>
                                        <div className="form1 my-3">
                                            <input {...register("subject", { required: true })} className='p-4 focus:ring-2 w-full focus:ring-blue-400  text-[18px] bg-[#7bd9f842] placeholder:text-gray-500 outline-none' placeholder='Subject' />
                                            {errors.subject && <span className='text-red-500'>This field is required</span>}
                                        </div>
                                        <div className="form1 my-3">
                                            <textarea {...register("message", { required: true })} rows='5' className='p-4 focus:ring-2 w-full text-[18px] focus:ring-blue-400 bg-[#7bd9f842] placeholder:text-gray-500 outline-none' placeholder='Message' />
                                            {errors.message && <span className='text-red-500'>This field is required</span>}
                                        </div>


                                        <div className="form-btn">
                                            {
                                                loadding ?
                                                    <button className='w-full text-center capitalize text-white bg-pink-600 rounded-lg p-5 text-[18px] flex items-center gap-2 justify-center font-semibold' disabled>Submitting please wait... <span className='loader2'></span></button>
                                                    :
                                                    <button className='w-full text-center capitalize text-white bg-blue-400 rounded-lg p-5 text-[20px] flex items-center gap-2 justify-center font-semibold'>Sent Message <FaArrowRight /></button>
                                            }
                                        </div>

                                    </form>
                                </div>
                            </Slide>
                        </div>
                        <div className="google-map">
                            <Slide right>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.743444100501!2d139.70856387626827!3d35.7325276665501!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188d673dd982c1%3A0x1033be7c3b2ccec8!2zSmFwYW4sIOOAkjE3MC0wMDEzIFRva3lvLCBUb3NoaW1hIENpdHksIEhpZ2FzaGlpa2VidWt1cm8sIDEtY2jFjW1l4oiSNDLiiJIxNCDvvJLvvJjlsbHkuqzjg5Pjg6s!5e0!3m2!1sen!2sbd!4v1693988025508!5m2!1sen!2sbd" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='w-full h-[350px]'></iframe>
                            </Slide>

                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default Contact;
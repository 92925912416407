import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaTrashAlt } from 'react-icons/fa'
import { Zoom } from 'react-reveal';
import { toast } from 'react-toastify';
import { backendAPI } from '../../backendapi/BackendAPI';
import CarModal from './CarModal';

const Car = () => {
    const [car, setCar] = useState([]);




    //load blogs
    //load blogs 
    useEffect(() => {
        fetch(`${backendAPI}/car/getAllCar`)
            .then(res => res.json())
            .then(data => setCar(data))
    }, [car])



    //handle Remove Data
    const handleRemovePost = (id) => {
        const confirmRemove = window.confirm("Do you want to delete this post?");
        if (confirmRemove) {
            fetch(`${backendAPI}/car/removeSingleCar/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(result => {
                    if (result?.success) {
                        toast.success(result?.message);
                    } else {
                        if (result?.error) {
                            toast.error(result?.message)
                        }
                    }
                })
        }
    }

    return (
        <section className='lg:px-12 my-12 overflow-hidden'>
            <div className="bg-blue-500 rounded-lg py-2 px-8 flex items-center justify-between">
                <div className="blog-title">
                    <h2 className='text-white font-semibold text-2xl'>Hero Section Slider</h2>
                </div>
                <div className="blog-thub">
                    <CarModal />
                </div>
            </div>
            <div className="blog-data">
                <h2 className='font-semibold text-2xl my-4'>Letest all Hero Slider</h2>
                <div className="show-blog">
                    {
                        car?.length === 0 ?
                            <div className="loadding">
                                <h2 className='text-slate-800 font-medium mb-2 text-2xl'>Loadding Please Wait.......</h2>
                            </div>
                            :

                            <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-x-5 gap-y-[40px]">
                                {
                                    car?.map((item, index) => {
                                        const { title, disc, thubnail, _id } = item;
                                        return (
                                            <div className="blog-item  bg-white rounded-lg shadow-md hover:translate-y-5 duration-[0.5s] ease-in-out transition-all" key={index}>
                                                <Zoom>
                                                    <div className="blog-photo relative">
                                                        <img src={thubnail} alt="car" className='object-cover relative group-hover:grayscale duration-[0.5s] ease-in-out transition-all rounded-t-lg h-[300px] w-full' />
                                                    </div>
                                                    <div className="p-2">
                                                        <h2 className='text-slate-800  my-3 text-2xl font-[600]'>{title}</h2>
                                                        <p className='text-gray-500 text-[18px]'>{disc?.length >= 100 ? disc?.slice(0, 200) : disc}...</p>
                                                    </div>
                                                    <div className="btn mt-2 pb-2">
                                                        <button className='p-1 text-white bg-rose-500 rounded-lg flex items-center gap-1 capitalize' onClick={() => handleRemovePost(_id)}>Remove Car <FaTrashAlt /></button>
                                                    </div>
                                                </Zoom>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div >
        </section >
    );
};

export default Car;
import React from 'react';
import { MdOutlineSupportAgent, MdSecurity, MdHandshake } from 'react-icons/md'
import { TfiGift } from 'react-icons/tfi'
import { Zoom } from 'react-reveal';
import Bounce from 'react-reveal/Bounce';

const serviceData = [
    {
        title: "Highly Secured",
        logo: <MdSecurity />,
        disc: 'Maintaining a high level of security is crucial for any business, and this applies to N.Z Corporations株式会社 as well'
    },
    {
        title: "Trusted Agents",
        logo: <MdHandshake />,
        disc: 'Trusted agents can help facilitate transactions, provide local expertise, and build relationships in foreign markets'
    },
    {
        title: "Get an Offer",
        logo: <TfiGift />,
        disc: "If you're looking to get an offer for second-hand cars from N.Z Corporations株式会社 I recommend visiting our official office "
    },
    {
        title: "Free Support",
        logo: <MdOutlineSupportAgent />,
        disc: ' We are here to provide free support and answer any questions or provide information to the best of my knowledge and capabilities'
    }
]

const Services = () => {
    return (
        <section className='my-24'>
            <div className="px-5 lg:px-12">
                <div className="service-title my-16">
                    <Bounce left>
                        <h2 className='main-title'>OUR FACILTIES</h2>
                    </Bounce>
                </div>
                <div className="service-main">
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-x-5 gap-y-[40px]">
                        {
                            serviceData?.map((item, index) => {
                                const { title, logo, disc } = item;
                                return (
                                    <div className="service-item group bg-white mb-6 rounded-lg p-3 overflow-hidden hover:bg-orange-400 shadow-md text-center hover:translate-y-3 cursor-pointer duration-[0.5s] ease-in-out transition-all" key={index}>
                                        <Zoom>
                                            <div className="h-20 w-20 border-[2px] group-hover:bg-white group-hover:rotate-180 duration-[0.5s] ease-in-out transition-all mx-auto flex items-center justify-center border-dotted border-orange-400 p-8 rounded-full">
                                                <h2 className='text-5xl text-orange-400'>{logo}</h2>
                                            </div>
                                            <h2 className='text-slate-800 group-hover:text-white my-3 text-2xl font-[600]'>{title}</h2>
                                            <p className='text-gray-600 font-[400] group-hover:text-white text-[19px]'>{disc}</p>
                                        </Zoom>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
import React, { useState } from 'react';
import { FaAngleRight, FaEnvelope, FaFacebookF, FaMapMarkerAlt, FaPhoneAlt, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { backendAPI } from '../../backendapi/BackendAPI';
import logo from '../../../images/extra/fot.png'

const Footer = () => {
    const [email, setEmail] = useState('')
    const [duplicate, setDuplicate] = useState('')

    //handle Subcribe Email
    const handleSumit = (e) => {
        e.preventDefault();

        const dataStore = {
            email: email,
            time: new Date().toDateString()
        }

        fetch(`${backendAPI}/feed/emailFeed`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(dataStore)
        })
            .then(res => res.json())
            .then(result => {
                if (result?.error?.includes('E11000 duplicate key error collection')) {
                    setDuplicate('already use this email');
                }
                if (result?.success) {
                    toast.success(result?.message)
                    setEmail('')
                    setDuplicate('')
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                        setEmail('')
                    }
                }
            })

    }

    return (
        <section className='footer-section'>
            <div className="bg-slate-800 py-12">
                <div className="px-5 lg:px-12">
                    <div className="footer-main">
                        <div className="grid xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-x-5 gap-y-[40px]">
                            <div className="footer-item">
                                <Link to='/'>
                                    <img src={logo} alt="NZ Corp.Ltd" className='object-cover lg:h-[130px] h-[100px]' />
                                </Link>
                                <p className='text-gray-400 my-3 font-[400] text-[19px]'>N.Z Corporations株式会社 is a company based in Japan and specializes in selling good quality second hand cars.</p>
                            </div>
                            <div className="footer-item">
                                <h2 className='font-semibold text-[20px] text-white capitalize'>Usefull Links</h2>
                                <div className="footer-menu mt-2">
                                    <ul className='space-y-3'>
                                        <li>
                                            <Link to='/' className='flex items-center gap-[2px] text-gray-400  hover:translate-x-5 text-[18px] duration-[0.5s] ease-in-out transition-all'><FaAngleRight />Home Page</Link>
                                        </li>
                                        <li>
                                            <Link to='/about' className='flex items-center gap-[2px] text-gray-400  hover:translate-x-5 text-[18px] duration-[0.5s] ease-in-out transition-all'><FaAngleRight /> About US</Link>
                                        </li>
                                        <li>
                                            <Link to='/services' className='flex items-center gap-[2px] text-gray-400  hover:translate-x-5 text-[18px] duration-[0.5s] ease-in-out transition-all'><FaAngleRight /> Our Services</Link>
                                        </li>
                                        <li>
                                            <Link to='/contact' className='flex items-center gap-[2px] text-gray-400  hover:translate-x-5 text-[18px] duration-[0.5s] ease-in-out transition-all'><FaAngleRight /> Contact US</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-item">
                                <h2 className='font-semibold text-[20px] text-white capitalize'>Contact Info</h2>
                                <div className="footer-contact space-y-3 mt-2">
                                    <p className='flex items-center gap-3 text-gray-400 text-[18px]'><FaMapMarkerAlt /> 千葉県野田市木間ケ瀬 4483-2</p>
                                    <p className='flex items-center gap-3 text-gray-400 text-[18px]'><FaEnvelope />n.zcorporationltd@gmail.com</p>
                                    <p className='flex items-center gap-3 text-gray-400 text-[18px]'><FaPhoneAlt /> 070-4343-3767</p>
                                    <p className='flex items-center gap-3 text-gray-400 text-[18px]'><FaPhoneAlt />047-199-2072</p>
                                </div>
                            </div>
                            <div className="footer-item">
                                <div className="branch">
                                    <h2 className='font-semibold text-[20px] text-white capitalize'>Branch Office Bangladesh</h2>
                                    <div className="footer-contact space-y-3 mt-2">
                                        <p className='flex items-center gap-3 text-gray-400 text-[18px]'><FaMapMarkerAlt />86 Inner circular VIP road, kazi tower (9th floor)Nayapaltan, Dhaka-1000</p>
                                        <p className='flex items-center gap-3 text-gray-400 text-[18px]'><FaPhoneAlt />+8801409961466</p>
                                    </div>
                                </div>

                                <div className="footer-contact  mt-2">
                                    <div className="subcribe-box relative my-5">
                                        <form onSubmit={handleSumit}>
                                            <input type="email" placeholder='Email Address' className='relative w-full outline-none p-2 focus:ring-1 focus:ring-orange-400 rounded-lg' value={email} onChange={(e) => setEmail(e.target.value)} required />
                                            <button className='bg-orange-400 py-2 p-2 text-white absolute right-0 rounded-r-lg'>Subcribe</button>
                                        </form>
                                    </div>
                                    <h2 className='text-white text-2xl font-bold text-center'>{duplicate}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-line h-[1px] w-full bg-gray-700 my-12"></div>
                    <div className="copyright">
                        <p className='text-gray-400 text-[18px] flex items-center justify-center flex-wrap'>© {new Date().getFullYear()} N.Z Corporations Ltd. All Rights Reserved. </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Footer;
import React from 'react';
import useActiveUser from '../../../hooks/useActiveUser';

const Profile = () => {
    const [user] = useActiveUser()

    return (
        <div className='px-5 lg:px-12 my-24'>
            <div className="w-full mx-auto lg:w-1/2 lg:mx-auto">
                <div className="flex items-center gap-5 flex-wrap">
                    <div className="profile shadow-lg p-2 rounded-lg bg-white text-center">
                        <img src={user?.profile} alt="Profile" className='object-cover h-24 w-24 rounded-full mx-auto' />
                        <h3 className='text-slate-800 capitalize font-medium text-[20px]'>{user?.userName}</h3>
                        <h3 className='text-slate-800  font-medium text-[20px]'>{user?.email}</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
import React from 'react';
import { FaEnvelope, FaFacebookF, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa'

const TopBar = () => {
    return (
        <section className='bg-blue-500 hidden lg:block px-5 lg:px-12 py-[10px]'>
            <div className="flex items-center justify-between flex-wrap">
                <div className="contact space-y-2">
                    <div className="top-left flex items-center gap-4">
                        <h4 className='flex items-center gap-2 text-[18px] text-white'><FaPhoneAlt />070-4343-3767</h4>
                        <h4 className='flex items-center gap-2 text-[18px] text-white'><FaPhoneAlt />047-199-2072</h4>
                        <h4 className='flex items-center gap-2 text-[18px] text-white'><FaEnvelope />n.zcorporationltd@gmail.com</h4>
                        <h4 className='flex items-center gap-2 text-[18px] text-white'><FaMapMarkerAlt />千葉県野田市木間ケ瀬 4483-2</h4>
                    </div>
                    <div className="top-left flex items-center gap-4">
                        <h4 className='flex items-center gap-2 text-[18px] text-white'><FaPhoneAlt />+8801409961466</h4>
                        <h4 className='flex items-center gap-2 text-[18px] text-white'><FaMapMarkerAlt /><span className='font-semibold'>Branch Office Bangladesh</span> = 86 Inner circular VIP road, kazi tower (9th floor)Nayapaltan, Dhaka-1000 (Bangladesh)</h4>
                    </div>
                </div>

                <div className="top-right">
                    <ul className='flex items-center gap-3'>
                        <p className='text-white text-xl'>Follow Us :</p>
                        <li>
                            <a href="https://www.facebook.com/profile.php?id=61558695964778&mibextid=4MzKDw5ooBP2efJs" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF className='text-white text-[25px] cursor-pointer bg-yellow-400 hover:rotate-180 duration-[0.5s] ease-in-out transition-all p-[4px] rounded-md hover:bg-orange-400 hover:text-white' />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default TopBar;
import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import AddCar from './AddCar';


const CarModal = () => {
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    return (
        <div>
            <button onClick={onOpenModal}>
                <FaPlus className='bg-white p-1 text-2xl cursor-pointer rounded-md' />
            </button>
            <Modal open={open} onClose={onCloseModal} center classNames={{
                'modal': 'blogModal'
            }}>
                <AddCar />
            </Modal>
        </div>
    );
};

export default CarModal;
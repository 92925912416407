import React from 'react';
import { HashLoader } from 'react-spinners';

const HomeAnimation = () => {
    return (
        <div className='grid place-content-center bg-slate-800 h-screen py-[300px]'>
            <HashLoader
                color="#f1c40f"
                size={100}
            />
        </div>
    );
};

export default HomeAnimation;
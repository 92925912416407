import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BsSearch } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
import { backendAPI } from '../../backendapi/BackendAPI';
import useActiveUser from '../../../hooks/useActiveUser';


const Admin = () => {
    const [users, setUsers] = useState([])
    const [search, setSearch] = useState('')

    const [user] = useActiveUser();
    const admin = user?.role === 'admin';
    const adminRoleId = user?._id;

    //load user api 
    useEffect(() => {
        fetch(`${backendAPI}/auth/allusers`)
            .then(res => res.json())
            .then(data => setUsers(data))
    }, [users])

    //Remove Single Users
    const handleRemoveAdmin = (id) => {
        const user = window.confirm('Do yu want delete this user?');

        if (user) {
            fetch(`${backendAPI}/auth/removeSingleUser/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(result => {
                    if (result?.success) {
                        toast.success(result?.message)
                    } else {
                        if (result?.error) {
                            toast.error(result?.message)
                        }
                    }
                })
        }
    }


    // Make Admin 
    const handleMakeAdmin = (id) => {
        fetch(`${backendAPI}/auth/makeAdmin/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(res => res.json())
            .then(result => {
                if (result?.success) {
                    toast.success(result?.message)
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                    }
                }
            })
    }






    return (
        <section className='all-users-section overflow-hidden'>
            {
                users?.length === 0 ?
                    <>
                        <h2 className='text-slate-800 font-medium my-12 text-2xl'>Loadding Please Wait.......</h2>
                    </>
                    :

                    <div className='my-8'>
                        <h1 className='text-slate-800 text-[25px] font-semibold mb-5'>Control Admin Access</h1>


                        <div className="rounded-lg border border-gray-200 shadow-md  overflow-x-auto w-1/3 md:w-full">
                            <table className="w-full border-collapse bg-white text-left text-sm text-gray-500">
                                <thead className="bg-gray-50">
                                    <tr className='text-center bg-slate-200'>
                                        <th scope="col" className="px-6 py-4 text-[18px] text-slate-800">Serial</th>
                                        <th scope="col" className="px-6 py-4 text-[18px] text-slate-800">Name</th>
                                        <th scope="col" className="px-6 py-4 text-[18px] text-slate-800">Email</th>
                                        <th scope="col" className="px-6 py-4 text-[18px] text-slate-800">Status</th>
                                        <th scope="col" className="px-6 py-4 text-[18px] text-slate-800">Admin</th>
                                        <th scope="col" className="px-6 py-4 text-[18px] text-slate-800">Action </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        users?.map((user, index) => {
                                            const { userName, email, _id, role } = user;
                                            return (
                                                <tr className="hover:bg-gray-300 transition ease-in-out shadow-xl duration-75 my-2" key={index}>
                                                    <td className="px-6 py-4 text-center text-slate-950 text-[20px]">{index + 1}</td>
                                                    <td className="px-6 py-4 text-center text-[18px] capitalize text-slate-900">{userName}</td>
                                                    <td className="px-6 py-4 text-center text-[18px] text-slate-900">{email}</td>
                                                    <td className="px-6 py-4 text-center text-[18px] text-slate-900">{role}</td>

                                                    <td className="px-6 py-4 text-center">
                                                        {
                                                            role === 'admin' ?
                                                                <button className='bg-rose-500 rounded-lg p-1 text-[18px] text-white disabled:'>Admin</button>
                                                                :
                                                                <button className='bg-blue-400 rounded-lg p-1 text-[18px] text-white' onClick={() => handleMakeAdmin(_id)}>Make Admin</button>
                                                        }

                                                    </td>
                                                    <td className="px-6 py-4 text-center">
                                                        <FiTrash2 className='text-rose-500 cursor-pointer text-center text-[20px] mx-auto' onClick={() => handleRemoveAdmin(_id)} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
        </section >
    )
};

export default Admin;
import React from 'react';
import logo from '../../images/extra/hero.png'
import Services from '../Home/services/Services';
import { Bounce, Slide } from 'react-reveal';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './About.css'


const About = () => {
    return (
        <section>
            <div className='allblog_item'>
                <Bounce left>
                    <div className="px-5 lg:px-12 my-24">
                        <h2 className='text-white font-bold mb-2 text-center lg:text-left text-6xl uppercase'>About us</h2>
                        <div className="flex items-center justify-center lg:justify-start gap-3">
                            <Link to='/' className='text-white font-medium text-2xl'>
                                Home
                            </Link>
                            <FaArrowRight className='text-white mt-1' />
                            <h2 className='text-white font-medium text-2xl'>Company Details</h2>
                        </div>
                    </div>
                </Bounce>
            </div>
            <div className='px-5 lg:px-12 my-24'>
                <div className="grid items-center xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-x-10 gap-y-[40px]">
                    <div className="about-left">
                        <Slide left>
                            <h2 className='text-2xl font-semibold text-gray-500 uppercase'>About us</h2>
                            <h2 className='text-4xl font-semibold text-blue-600 my-2'>N.Z Corporations Ltd</h2>
                            <p className='text-gray-600 text-[20px] font-[400]'>N.Z Corporations株式会社 is a company based in Japan and specializes in selling good quality second hand cars. The company was established in July 2023. Our services include both selling cars in the local market in Japan as well as exporting Japanese cars outside Japan. We tend to provide the best services by actively communicating with our customers and serving our services according to their wants. </p>
                            <p className='text-gray-600 text-[20px] font-[400] my-3'>As the company continues to grow and establish itself in the market, it may explore opportunities for expanding its services, such as offering additional automotive-related products or venturing into related markets. With a focus on quality, customer satisfaction, and adaptability, N.Z Corporations株式会社 is well-positioned to excel in the second-hand car market, both locally in Japan and in the international arena. Their dedication to meeting customer needs and maintaining high standards sets them apart in the industry.</p>
                            <Link to='/' className='my-4 block'>
                                <button className='flex items-center bg-blue-500 px-5 rounded-lg hover:bg-pink-500 hover:translate-x-5 duration-[0.5s] ease-in-out transition-all py-2 text-white gap-1 font-semibold text-[18px]'>Expole More <FaArrowRight /></button>
                            </Link>
                        </Slide>
                    </div>
                    <div className="about-right">
                        <Slide right>
                            <img src={logo} alt="car" className='object-cover' />
                        </Slide>
                    </div>
                </div>
            </div>
            {/* service */}
            <Services />

        </section >
    );
};

export default About;
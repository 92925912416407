import React, { useState } from 'react';
import { backendAPI } from '../../backendapi/BackendAPI';
import useActiveUser from '../../../hooks/useActiveUser';
import { toast } from 'react-toastify';


const TextTitle = () => {
    const [title, setTitle] = useState('')
    const [loadding, setLoadding] = useState(false);
    const [user] = useActiveUser();


    //handle post data
    const handleSubmitData = (e) => {
        e.preventDefault();

        setLoadding(true);

        fetch(`${backendAPI}/slide/postData`, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
            },
            body: JSON.stringify({ title, id: user?._id })
        })
            .then(res => res.json())
            .then(result => {
                console.log("result..", result);
                if (result?.success) {
                    toast.success(result?.message)
                    setLoadding(false);
                } else {
                    if (result?.error) {
                        toast.error(result?.message)
                        setLoadding(false);
                    }
                }
            })

    }

    return (
        <div className='px-12 py-24'>
            <h3 className='font-bold my-3 text-slate-700 text-2xl'>Add Slide Title</h3>
            <form onSubmit={handleSubmitData}>
                <textarea type="text" placeholder='Add title here..' rows={8} className='w-full ring-1 text-xl ring-blue-400 rounded-md p-2 outline-none focus:ring-2 focus:ring-yellow-400' onChange={(e => setTitle(e.target.value))} />
                {
                    loadding ?
                        <button className='bg-yellow-400 text-white rounded-lg px-4 py-2 font-semibold text-xl'>Loadding please wait..</button>
                        :
                        <button className='bg-blue-400 text-white rounded-lg px-4 py-2 font-semibold text-xl'>Add Text Submit</button>
                }
            </form>
        </div>
    );
};

export default TextTitle;
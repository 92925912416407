import React from 'react';
import Hero from './hero/Hero';
import Services from './services/Services';







const Home = () => {
    return (
        <div>
            <Hero />
            <Services />
        </div>
    );
};

export default Home;
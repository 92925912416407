import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { backendAPI } from '../../../components/backendapi/BackendAPI';
import './Hero.css'




const Hero = () => {
    const [car, setCar] = useState([]);


    //load car api
    useEffect(() => {
        fetch(`${backendAPI}/car/getAllCar`)
            .then(res => res.json())
            .then(data => setCar(data))
    }, [car])


    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <Carousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
            showDots={false}
        >
            {
                car?.map((item, index) => {
                    const { title, thubnail, disc } = item;
                    return (
                        <div className='hero-slide' key={index}>
                            <img src={thubnail} alt="photo" className='object-cover h-[600px] w-full ' />
                            <div className="hero-title absolute top-[250px]  w-full px-5 lg:left-24 z-50">
                                <h1 className='lg:text-5xl text-4xl font-semibold text-white uppercase'>{title}</h1>
                                <p className='text-gray-200 my-3 text-[18px]'>{disc?.length >= 100 ? disc?.slice(0, 100) : disc}...</p>
                                <Link to='/about' className='my-4 block'>
                                    <button className='flex items-center bg-blue-500 px-5 capitalize rounded-lg hover:bg-pink-500 hover:translate-x-5 duration-[0.5s] ease-in-out transition-all py-2 text-white gap-1 font-semibold text-[18px]'>Explore Now <FaArrowRight /></button>
                                </Link>
                            </div>
                        </div>
                    )
                })
            }
        </Carousel>
    );
};

export default Hero;
import React from 'react';
import { FaCarAlt, FaEnvelope, FaUserCheck, FaUserFriends } from 'react-icons/fa';
import { MdDashboard, MdOutlineContactPhone, MdPostAdd } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import './DashSlide.css'
import { useState } from 'react';
import { BsFillArrowLeftCircleFill, BsPencilSquare } from 'react-icons/bs';


const slidebarData = [
    {
        title: 'signUp users',
        path: 'all-users',
        logo: <FaUserCheck />
    },
    {
        title: 'contact Message',
        path: 'contact',
        logo: <MdOutlineContactPhone />
    },
    {
        title: 'add service',
        path: 'add-car',
        logo: <FaCarAlt />
    },
    {
        title: 'admin access',
        path: 'admin',
        logo: <FaUserFriends />
    },
    {
        title: 'Subscribe Email',
        path: 'subscribe',
        logo: <FaEnvelope />
    },
    {
        title: 'Add Slide Title',
        path: 'title',
        logo: <BsPencilSquare />
    },
]
const DashSlide = () => {
    const [open, setOpen] = useState(false);
    const toogleBar = () => setOpen(!open);

    return (
        <div className={`bg-slate-900 h-screen relative ${open ? "w-[100px]" : "w-[250px]"}`}>
            <h2 className='text-white font-bold text-2xl px-5 py-2 extra-color flex items-center gap-2'>
                <MdDashboard />
                <span className={`${open && 'hidden'}`}> Dashboard</span>
            </h2>
            <BsFillArrowLeftCircleFill className={`text-white text-3xl absolute right-0 top-9 cursor-pointer ${open && 'rotate-180'}`} onClick={toogleBar} />
            <div className="dash-meu">
                {
                    slidebarData?.map((item, index) => {
                        const { title, path, logo } = item;
                        return (
                            <div className="slidebar_item" key={index}>
                                <NavLink to={`/dashboard/${path}`} className='my-3 px-5 py-3 text-white font-medium text-[18px] capitalize'>
                                    <div className="flex items-center gap-x-2">
                                        <span>{logo}</span>
                                        <span className={`${open && 'hidden'}`}>{title}</span>
                                    </div>
                                </NavLink>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default DashSlide;
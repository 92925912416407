import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi'
import { BsSearch } from 'react-icons/bs'
import { toast } from 'react-toastify';
import { backendAPI } from '../../backendapi/BackendAPI';

const NewsFeed = () => {
    const [user, setUser] = useState([]);
    const [loadding, setLoadding] = useState(false);


    useEffect(() => {
        setLoadding(true);
        fetch(`${backendAPI}/feed/getAllEmail`)
            .then(res => res.json())
            .then(data => {
                setUser(data);
                setLoadding(false)
            })
    }, [user])





    return (
        <section>
            <div className="newsfeed">

                {
                    user?.length === 0 ?
                        <>
                            <h2 className='text-slate-800 font-medium my-12 text-2xl'>Loadding Please Wait.......</h2>
                        </>
                        :
                        <>
                            <div className="user-list">
                                <div className='my-8'>
                                    <h1 className=' text-[25px] font-semibold text-slate-800 capitalize my-5'> All Subscribe Users</h1>

                                    <div className="shadow-md  w-1/3 md:w-full overflow-x-hidden">
                                        <table className="w-full">
                                            <thead className="bg-slate-300 ">
                                                <tr className='text-center rounded-xl'>
                                                    <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Serial</th>
                                                    <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Email</th>
                                                    <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Status</th>
                                                    <th className="px-6 py-4 text-[18px] text-slate-800 font-[500]">Time</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    user?.map((user, index) => {
                                                        const { time, email } = user;
                                                        return (
                                                            <tr className="border border-transparent hover:border hover:border-blue-400 cursor-pointer transition my-5 ease-in-out duration-75 shadow-xl" key={index}>
                                                                <td className="px-6 py-4 text-center font-semibold text-slate-500 text-[20px]">{index + 1}</td>

                                                                <td className="px-6 py-4 text-center text-[16px] font-semibold text-slate-500">{email}</td>
                                                                <td className="px-6 py-4 text-center">
                                                                    <span
                                                                        className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600"
                                                                    >
                                                                        <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
                                                                        Active
                                                                    </span>
                                                                </td>
                                                                <td className="px-6 py-4 text-center text-[16px] font-semibold text-slate-500">{time}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </section>
    );
};

export default NewsFeed;
import React, { useEffect, useState } from 'react';
import { backendAPI } from '../../backendapi/BackendAPI';
import useActiveUser from '../../../hooks/useActiveUser';

const SlideMarquee = () => {
    const [data, setData] = useState({});
    const [loadding, setLoadding] = useState(true);

    const [user] = useActiveUser();

    useEffect(() => {
        const fetchingData = async () => {
            await fetch(`${backendAPI}/slide/getData/${user?._id}`)
                .then(res => res.json())
                .then(data => {
                    setData(data)
                    setLoadding(false)
                })
                .catch(err => {
                    setLoadding(false)
                })
        }
        fetchingData();
    }, [])





    return (
        <div>
            <marquee behavior="scroll" direction="left" scrollamount="10">
                <h3 className='text-xl text-red-500'>We import cars of your choice within your budget range directly from Japan and deliver directly to customers.</h3>
            </marquee>
        </div>
    );
};

export default SlideMarquee;
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaAngleDown, FaBars } from 'react-icons/fa';
import './Navbar.css'
import { toast } from 'react-toastify';
import logo from '../../../images/extra/logo.png'
import useActiveUser from '../../../hooks/useActiveUser';


const Navbar = () => {
    const [show, setShow] = useState(false);
    const [profile, setProfile] = useState(false);
    const [bar, setBar] = useState(false);
    const handleShow = () => setShow(!show)
    const showProfile = () => setProfile(!profile)


    //active user 
    const [user] = useActiveUser();




    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY >= 400) {
                setBar(true)
            } else {
                setBar(false)
            }
        })
    }, [])




    //handle Logout 
    const handleLogout = () => {
        localStorage.removeItem('token')
        toast.success("Account Logout Successfully Done")
    }

    return (
        <nav className=''>
            <div className={`lg:flex lg:items-center bg-white lg:justify-between w-full px-5 lg:px-12  py-5 ${bar ? 'fixed top-0 left-0 right-0 px-5 lg:px-12  z-50 shadow-lg border-b-2 border-gray-300' : 'z-50 shadow-lg border-b-2 border-gray-300'}`}>
                <div className="logo">
                    <div className="flex items-center justify-between">
                        <Link to='/'>
                            <img src={logo} alt="NZ Corp.Ltd" className='object-cover lg:h-[130px] h-[100px]' />
                        </Link>
                        <button className='lg:hidden text-3xl font-bold rounded-lg bg-rose-400 p-2' onClick={handleShow}>
                            <FaBars className='text-white' />
                        </button>
                    </div>
                </div>
                <div className="menu">
                    <ul className={`lg:flex lg:items-center gap-x-12 ${show ? 'lg:block' : 'hidden'}`}>
                        <li>
                            <NavLink to='/' onClick={handleShow}>
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/about' onClick={handleShow}>
                                about us
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/services' onClick={handleShow}>
                                services
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/contact' onClick={handleShow}>
                                contact
                            </NavLink>
                        </li>
                        <li>
                            {
                                user?.email ?
                                    <>
                                        <div className="profile-logo cursor-pointer relative" onClick={showProfile}>
                                            <div className="flex items-center gap-1 ">
                                                <img src={user?.profile} alt="user" className='w-12 h-12 rounded-full' />
                                                <FaAngleDown />
                                            </div>
                                            <div className={`profile-details bg-blue-400 w-[180px]  rounded-md py-2  absolute top-[50px] right-0 lg:right-4 z-50 ${profile ? "block" : 'hidden'}`}>
                                                <h2 className='text-white capitalize px-3'>{user?.userName}</h2>
                                                <hr />
                                                <div className="profile-sub ">
                                                    <NavLink to='/profile' className='profile-link block px-3' onClick={handleShow}>
                                                        Profile
                                                    </NavLink>
                                                    <hr className={`${user?.role === 'admin' ? 'block' : 'hidden'}`} />
                                                    {
                                                        user?.role === 'admin' ?
                                                            <NavLink to='/dashboard' className='profile-link block px-3' onClick={handleShow}>
                                                                dashboard
                                                            </NavLink>
                                                            : ''
                                                    }
                                                </div>
                                                <hr />
                                                <h2 className='text-white capitalize cursor-pointer px-3' onClick={handleLogout}>Logout</h2>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <NavLink to='/login' onClick={handleShow}>
                                            Login
                                        </NavLink>
                                    </>
                            }
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;